import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const TagListContainer = styled('div')`
  display:block;
  margin: 10px 0 20px 0;
  font-size: 13px;
`;

const TagContainer = styled('span')`
  margin-right: 10px;
  background-color: #f5f3f7;
  color: #56658d;
  border-radius: 6px;
  padding:5px 10px
`;


const Tag = ({tag}) => (
  <TagContainer>{tag}</TagContainer>
)

const TagList = ({ tags }) => (
  <>
    {tags && 
      <TagListContainer>
        {tags.map((tag, i) => (
          <Tag tag={tag} key={i} />
        ))}
      </TagListContainer>
    }
  </>
);

TagList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TagList;
