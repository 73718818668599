import React from "react"
import { Link } from "gatsby"
import Header from './header/Header'
import { rhythm, scale } from "utils/typography"
import 'scss/mellow.scss'

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

   
    return (
      <div>
        <Header />
        
        <main>{children}</main>
        <footer>
        </footer>
     
      </div>
    )
  }
}

export default Layout
