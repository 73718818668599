import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

import './post.scss'
import Bio from "components/bio"
import Layout from "components/layout"
import SEO from "components/SEO/SEO"
import { rhythm, scale } from "utils/typography"
import BaseMap from 'components/map/BaseMap'
import TagList from 'components/tags/TagList'
import ShareButtons from 'components/ShareButtons'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'

class MapPostTemplate extends React.Component {
  state = {
    showMap: false
  }
  render() {
    const { mdx, json } = this.props.data
    const post = mdx
    const { siteTitle, siteUrl } = this.props.data.site.siteMetadata
    const { previous, next } = this.props.pageContext
    const { points, lines } = json

    let { showMap } = this.state
    return (
      <>
          <div 
            className="toggle-map-button" 
            onClick={()=>this.setState({showMap:!showMap})}>
            {
              showMap ?
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M552 64H88c-13.234 0-24 10.767-24 24v8H24c-13.255 0-24 10.745-24 24v280c0 26.51 21.49 48 48 48h504c13.233 0 24-10.767 24-24V88c0-13.233-10.767-24-24-24zM32 400V128h32v272c0 8.822-7.178 16-16 16s-16-7.178-16-16zm512 16H93.258A47.897 47.897 0 0 0 96 400V96h448v320zm-404-96h168c6.627 0 12-5.373 12-12V140c0-6.627-5.373-12-12-12H140c-6.627 0-12 5.373-12 12v168c0 6.627 5.373 12 12 12zm20-160h128v128H160V160zm-32 212v-8c0-6.627 5.373-12 12-12h168c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12H140c-6.627 0-12-5.373-12-12zm224 0v-8c0-6.627 5.373-12 12-12h136c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12H364c-6.627 0-12-5.373-12-12zm0-64v-8c0-6.627 5.373-12 12-12h136c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12H364c-6.627 0-12-5.373-12-12zm0-128v-8c0-6.627 5.373-12 12-12h136c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12H364c-6.627 0-12-5.373-12-12zm0 64v-8c0-6.627 5.373-12 12-12h136c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12H364c-6.627 0-12-5.373-12-12z"/></svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M560.02 32c-1.96 0-3.98.37-5.96 1.16L384.01 96H384L212 35.28A64.252 64.252 0 0 0 191.76 32c-6.69 0-13.37 1.05-19.81 3.14L20.12 87.95A32.006 32.006 0 0 0 0 117.66v346.32C0 473.17 7.53 480 15.99 480c1.96 0 3.97-.37 5.96-1.16L192 416l172 60.71a63.98 63.98 0 0 0 40.05.15l151.83-52.81A31.996 31.996 0 0 0 576 394.34V48.02c0-9.19-7.53-16.02-15.98-16.02zM30.63 118.18L176 67.61V387.8L31.91 441.05l-1.28-322.87zM208 387.71V67.8l160 56.48v319.91l-160-56.48zm192 56.68V124.2l144.09-53.26 1.28 322.87L400 444.39z"/></svg>

            }
            display {showMap ? 'article':'map'}
          </div>

          <div className="post-container">
            <div className={`post ${showMap ? 'hide-post-mobile' : ''}`} style={{
              marginLeft: `auto`,
              marginRight: `auto`,
              maxWidth: 590,
              padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            }}>
            {post.frontmatter.featuredImage && 
              <>
              <Img sizes={post.frontmatter.featuredImage.childImageSharp.sizes} />
              <div className="picture-meta"><p>{post.frontmatter.featuredImageMeta}</p></div>
              </>
            }
            <div className="post-title-container">
              <h1>{post.frontmatter.title}</h1>
              <p
                style={{
                  ...scale(-1 / 5),
                  display: `block`,
                  marginBottom: rhythm(1),
                  marginTop: rhythm(-1),
                }}
              >
                {post.frontmatter.date}
              </p>
              <TagList tags={post.frontmatter.tags}/>
              <ShareButtons
                link={siteUrl + post.fields.slug}/>
            </div>

            <MDXRenderer>{post.code.body}</MDXRenderer>
          
            <hr
              style={{
                marginBottom: rhythm(1),
              }}
            />

            <Bio />

            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
          </div>

          <div className={`map ${showMap ? 'show-map-mobile':''}`}>
            <div className="map-wrapper">
              <BaseMap 
                markers={points}
                lines={lines}
              />
             </div>
          </div>
        </div>
        </>
    )
  }
}

export default ({ location, data, pageContext }) => (
  <Layout location={location} title={data.mdx.frontmatter.title}>
    <SEO
      title={data.mdx.frontmatter.title}
      image={data.site.siteMetadata.siteUrl+data.mdx.frontmatter.featuredImage.childImageSharp.sizes.src}
      description={data.mdx.frontmatter.description || data.mdx.excerpt}
      pathname={location.pathname}
    />
    <MapPostTemplate
      data={data}
      pageContext={pageContext}/>
  </Layout>
)

export const pageQuery = graphql`
  query($slug: String!,$pointsID: String) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      fields {
        slug
      }
      code {
        body
      }
      excerpt(pruneLength: 160)
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
        featuredImageMeta
        featuredImage {
          childImageSharp{
            sizes(maxWidth: 630) {
                ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
    json(id: { eq: $pointsID }) {
      lines {
        name
        coordinates {
          lat
          lng
        }
      }
      points {
        name
        coordinates
        order
        icon
      }
    }
  }
`

