import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

import './home.scss'
import Bio from "components/bio"
import Layout from "components/layout"
import SEO from "components/SEO/SEO"
import { rhythm } from "utils/typography"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO />
        <div style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}>
        <Bio />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
            <div key={node.fields.slug} className="blog-post-preview">
              {node.frontmatter.featuredImage && 
                <Img 
                  sizes={node.frontmatter.featuredImage.childImageSharp.sizes}
                  className="featured-image"
                />
              }
              <div className="blog-post-preview-content">
                <h3
                  className="post-title"
                  style={{
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                    {title}
                </h3>
                <small>{node.frontmatter.date}</small>
                <p
                  className="blog-post-preview-description"
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </div>
            </div>
            </Link>
          )
        })}
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredImage {
              childImageSharp{
                  sizes(maxWidth: 630) {
                      ...GatsbyImageSharpSizes
                  }
              }
            }
          }
        }
      }
    }
  }
`






