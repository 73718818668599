import React from 'react'
import Link from 'gatsby-link'
import styled from '@emotion/styled'

import './Header.scss'
import Mellow from './Mellow'

const Header = styled('header')`
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #f5f3f7;
  box-sizing: border-box;
  display: flex;
  height: 60px;
  justify-content: space-between;
  left: 0;
  padding-left: 40px;
  padding-right: 10px;
  position: sticky;
  right: 0;
  top: 0;
  z-index: 1000;
`

const HomeLink = styled(Link)`
  display: block;
  flex-shrink: 0;
  line-height: 1;
  margin-right: auto;
  @media only screen and (max-width: 768px) {
    margin-top: 0px;
    padding-left: 0px;
  }
`

const SearchLink = styled(Link)`
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  margin: 0;
  margin-right: 30px;
  min-width: 0;
  position: relative;
  font-weight: 600;
  color: #595959;
`

const ListGear = styled(Link)`
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  margin: 0;
  margin-right: 30px;
  min-width: 0;
  position: relative;
  font-weight: 600;
  color: #595959;
`

const External = styled('a')`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0;
  margin-right: 20px;
  min-width: 0;
  position: relative;
  font-weight:600;
  color: #222 !important;
  font-size:15px;
  opacity: .9;
  :hover {
    opacity:1
  }
`;

const ExternalText = styled('p')`
  margin:0;
  font-size: 15px;
  @media (max-width: 620px) {
    display:none;
  }
`

export default class SearchHeader extends React.Component {
  render() {

    return (

        <Header>
            
          <HomeLink to="/">
            <Mellow height={20} />
          </HomeLink>
   
          <External target="_blank" href="https://borrowmellow.com">
            <ExternalText>check out Mellow</ExternalText>
            <svg className="external-arrow" height="15" viewBox="0 0 576 512"><path fill="currentColor" d="M448 241.823V464c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h339.976c10.691 0 16.045 12.926 8.485 20.485l-24 24a12.002 12.002 0 0 1-8.485 3.515H54a6 6 0 0 0-6 6v340a6 6 0 0 0 6 6h340a6 6 0 0 0 6-6V265.823c0-3.183 1.264-6.235 3.515-8.485l24-24c7.559-7.56 20.485-2.206 20.485 8.485zM564 0H428.015c-10.658 0-16.039 12.93-8.485 20.485l48.187 48.201-272.202 272.202c-4.686 4.686-4.686 12.284 0 16.971l22.627 22.627c4.687 4.686 12.285 4.686 16.971 0l272.201-272.201 48.201 48.192c7.513 7.513 20.485 2.235 20.485-8.485V12c0-6.627-5.373-12-12-12z"/></svg>
          </External>
              
      </Header>

    )
  }
}
