import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

import './post.scss'
import Bio from "components/bio"
import Layout from "components/layout"
import SEO from "components/SEO/SEO"
import { rhythm, scale } from "utils/typography"
import ShareButtons from 'components/ShareButtons'
import TagList from 'components/tags/TagList'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const { siteTitle, siteUrl } = this.props.data.site.siteMetadata
    const { previous, next } = this.props.pageContext

    return (
        <div style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}>
        <Img sizes={post.frontmatter.featuredImage.childImageSharp.sizes} />
        <div className="post-title-container">
        <h1>{post.frontmatter.title}</h1>
        <p
          style={{
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: rhythm(1),
            marginTop: rhythm(-1),
          }}
        >
          {post.frontmatter.date}
        </p>
        <TagList tags={post.frontmatter.tags}/>
        <ShareButtons
                link={siteUrl + post.fields.slug}/>
                </div>
                <div className="post-wrapper">
        <MDXRenderer >{post.code.body}</MDXRenderer></div>
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <Bio />

        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
        </div>
    )
  }
}


export default ({ location, data, pageContext }) => (
  <Layout location={location} title={data.mdx.frontmatter.title}>
    <SEO
      title={data.mdx.frontmatter.title}
      image={data.site.siteMetadata.siteUrl+data.mdx.frontmatter.featuredImage.childImageSharp.sizes.src}
      description={data.mdx.frontmatter.description || data.mdx.excerpt}
      pathname={location.pathname}
    />
    <BlogPostTemplate
      data={data}
      pageContext={pageContext}/>
  </Layout>
)

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      fields {
        slug
      }
      code {
        body
      }
      frontmatter {
        title
        tags
        featuredImage {
          childImageSharp{
            sizes(maxWidth: 630) {
                ...GatsbyImageSharpSizes
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`


// markdownRemark(fields: { slug: { eq: $slug } }) {
//   id
//   excerpt(pruneLength: 160)
//   html
//   fields {
//     slug
//   }
//   frontmatter {
//     title
//     date(formatString: "MMMM DD, YYYY")
//     description
//     tags
//     featuredImage {
//         childImageSharp{
//             sizes(maxWidth: 630) {
//                 ...GatsbyImageSharpSizes
//             }
//         }
//     }
//   }
// }