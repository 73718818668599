import React from "react"
import Layout from "components/layout"
import SEO from "components/SEO/SEO"

export default class Feedback extends React.Component {
	render() {
		return (
			<Layout location={this.props.location}>
        		<SEO />
				<div id="feedback-page">
			        <div className="content-wrap">
			          <section className="page-title form-title">
			            <h2>Give us feedback</h2>
			          </section>
					<form
				        role="feedback-form"
				        className="feedback-form"
				        id="feedback-form"
				        data-netlify="true" data-netlify-honeypot="bot-field">
				        <input type="hidden" name="bot-field" />
				        <section className="form-page-container">
				          <div className="auth-page-subcontainer">
				            <div className="auth-page-flex">
				              <fieldset className="fieldset fieldset-with-label">
				                <label htmlFor="fname">First Name</label>
				                <input
				                  type="fname"
				                  name="fname"
				                  autoFocus
				                  required
				                  id="fname"
				                  maxLength="250"
				                />
				              </fieldset>

				              <fieldset className="fieldset fieldset-with-label">
				                <label htmlFor="email">Email (optional)</label>
				                <input
				                  type="email"
				                  name="email"
				                  id="email"
				                />
				              </fieldset>

				              <fieldset className="fieldset fieldset-with-label">
				                <label htmlFor="feedback">Feedback</label>
				                <textarea
				                  type="feedback"
				                  name="feedback"
				                  required
				                  rows="5"
				                  id="feedback"
				                />
				              </fieldset>

				          
				              <section className="feedback-page-submit">
				                <button className="cta-btn" type="submit" value="Submit">
				                  Send feedback
				                </button>
				              </section>
				            </div>
				          </div>
				        </section>
				    </form>
				</div>
				</div>


			</Layout>
		)
	}
}